


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, setInstanceVariables} from "@/config";
import Axios from "axios";


@Component
export default class ManageAllUser extends Vue {
  public userInfo = [];
  public userLoader = false;
  public skip = 0;
  public searchByType = null;
  public searchLoanString = "";
  public descending = true;
  public allUsers = [];
  public currentLengthOnScroll = 0;
  public showLoaderScroll = false;
  public totalCount = 0;
  public status = null;
  public password = null;
  public identityId = null;
  public errorAction = false;
  public searchUserStr = null;
  public emailExist = false;
  public newSuperAdminEmail = '';
  public currentEntitlements = {};
  public entitlementsList = [];
  public currentUser = {_id: '', email: ''};

  public get userType () {
    return this.$store.state.sessionObject.type;
  }

  public get userId () {
    return this.$store.state.sessionObject.userId;
  }
  
  public get isEditOtherAdmins () {
    return this.$store.state.sessionObject.userInfo?.entitlements?.createOtherSuperAdmins === true;
  }

  public async fetchUserData(mountCall: boolean = false) {
    try {
      this.skip = mountCall ? 0 : this.skip + 25;
      if (mountCall) {
        this.userLoader = true;
      }
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getAllUsersInfo",
        {
          type: this.searchByType,
          searchUserStr: this.searchUserStr,
          status:this.status,
          options: {
            skip: this.skip,
            limit: 25,
          },
          desc: this.descending,
        });

        if (mountCall) {
          this.userInfo = response.data.user;
          this.totalCount = response.data.totalCount;
        } else {
          this.userInfo = this.userInfo.concat(response.data.user);
        }
        this.currentLengthOnScroll = this.userInfo.length;
    } catch (error) {
      console.error(error);
    } finally {
      this.userLoader = false;
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.showLoaderScroll && this.currentLengthOnScroll < this.totalCount) {
      this.showLoaderScroll = true;
      await this.fetchUserData(false);
      this.showLoaderScroll = false;
    }
  }

  public async getDataOnChange(){
    try {
     await this.fetchUserData(true);
    } catch (error) {

    }
  }

  public showAssumeIdentityModal (modalName, identityId){
    this.identityId = identityId;
    this.$modal.show(modalName, {  })
  }

  public hideAssumeIdentityModal() {
    this.identityId = null;
    this.password = null;
    this.errorAction = false
    this.$modal.hide("assumeIdentityModal");
  }

  public async updateTokenForAssumeIdentity () {
    try {
      this.errorAction = false;
      let response = await Axios.post(
          BASE_API_URL + "superadmin/assume-identity",
          {
            password: this.password,
            identityId: this.identityId,
          });
      if (response.status === 200) {
        this.errorAction = false;
        await this.$store.dispatch("setIdentityAssumedMode", true);
        await this.$store.dispatch("setCopySuperAdminSessionObject", this.$store.state.sessionObject);
        await this.$store.dispatch("setUserInfoForIdentityAssumedMode", { ...response.data.userInfo, userType: response.data.type });
        await this.$store.dispatch("setSession", response.data);
        await setInstanceVariables();
        await this.$store.dispatch("setSidenavLinks", response.data);
        this.$router.push("/dashBoard");
      }
    }  catch (error) {
      const { response: { data = {} } = {}} = error;
      this.errorAction = data.type === 'password';
      console.log(error)
    }
  }
  
  public async openModalCreateNewSuperAdmin(){
    try {
      let response = await Axios.get(
          BASE_API_URL + "superadmin/adminEntitlementsList?",{ params: {userType: "Super Admin"} });
      this.entitlementsList = response.data.map( e => {
        return {...e, value:false}
      })
      this.$modal.show("createNewSuperAdminModal");
    }  catch (error) {
      console.log(error)
    }
  }

  public closeModalCreateNewSuperAdmin () {
    this.emailExist = false;
    this.newSuperAdminEmail = '';
    this.$modal.hide("createNewSuperAdminModal");
  }
  
  public async createNewSuperAdmin () {
    try {
      let entitlements = this.entitlementsList.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

      if (this.errors.items.length !== 0) {
        return;
      }

      let response = await Axios.post(
          BASE_API_URL + "superadmin/createNewAdmin", {
            email: this.newSuperAdminEmail,
            entitlements: entitlements
          });
      
      if (response.data.status === 'duplicateEmail') {
        this.emailExist = true
        this.$snotify.error("Email already exist");
      } else {
        this.fetchUserData(true)
        this.emailExist = false;
        this.newSuperAdminEmail = '';
        this.$snotify.success("Super Admin is created");
        this.closeModalCreateNewSuperAdmin()
      }
    }  catch (error) {
      console.log(error)
    }
  }

  public async showChangeEntitlementsModal (modalName, currentUser) {
    this.currentUser = currentUser;
    try {
      let response = await Axios.post(
          BASE_API_URL + "superadmin/updateEntitlements", {
            userId: this.currentUser._id
          });
      this.currentEntitlements = response.data.entitlements

      let res = await Axios.get(
          BASE_API_URL + "superadmin/adminEntitlementsList?",{ params: {userType: currentUser.userType} });
      this.entitlementsList = res.data.map( (e, i) => {
          return {...e, value: false}
      })

      this.entitlementsList = this.entitlementsList.map( e => {
        e.value = this.currentEntitlements[e.key]
        return e
      })
      this.$modal.show(modalName)
    } catch (error) {
      console.log(error)
    }
  }

  public closeChangeEntitlementsModal () {
    this.$modal.hide("changeEntitlementsModal");
  }
  
  public async updateEntitlementsForAdmin () {
    try{
      let entitlements = this.entitlementsList.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
      let response =  await Axios.post(
          BASE_API_URL + "superadmin/updateEntitlements", {
            userId: this.currentUser._id,
            entitlements: entitlements
          });
      if (response.status === 200) {
        this.$snotify.success("Entitlements is change");
      } else  this.$snotify.error("Something went wrong! Please contact Admin.");
    } catch (error) {
      this.$snotify.error("Something went wrong! Please contact Admin.");
      console.log(error)
    }
    this.closeChangeEntitlementsModal()
  }

  public async activateAdmin () {
    try{
      let response = await Axios.post(
          BASE_API_URL + "superadmin/activateAdmin", {
            userId: this.currentUser._id,
            email:  this.currentUser.email
          });
      if (response.status === 200) {
        this.$snotify.success("Super Admin is activated");
      } else  this.$snotify.error("Something went wrong! Please contact Admin.");
    } catch (error) {
      this.$snotify.error("Something went wrong! Please contact Admin.");
      console.log(error)
    }
    this.closeChangeEntitlementsModal()
  }

  mounted(){
    this.fetchUserData(true)
  }
}
