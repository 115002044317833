


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({ components: {} })
export default class ProcessorDetailsComponent extends Vue {
  public processorInfo = [];

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public async getProcessorLoanDetails() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getProcessorLoanDetails",
        {});
      this.processorInfo = response.data;
    } catch (error) {
      console.log(error);
    }
  }
  mounted() {
    this.getProcessorLoanDetails();
  }
}
